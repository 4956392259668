<template>
  <div>
    <a-row>
      <a-col :span="24">
        <div>
          <a-row class="align-items-center mb-4" style="margin: -30px; padding: 15px 30px 22px 30px; background: #fff;">
            <a-col :span="10">
              <h3 class="mb-0" style="font-size: 20px;">Analytics</h3>
            </a-col>
            <a-col :span="14" class="text-right">
              <a-button @click="showFilters" size="large" class="mr-2">
                <a-icon type="filter" /> Filters
              </a-button>
            </a-col>
          </a-row>
          <a-drawer
            title="Analytics Filters"
            placement="top"
            :closable="true"
            :visible="filtersDrawer"
            :wrapStyle="{ opacity: '1.0', overflow: 'hidden'}"
            :maskStyle="{ opacity: '0', backgroundColor: 'rgba(0,0,0,0.0)'}"
            height="176px"
            wrapClassName="filter-drawer"
            @close="hideFilters"
            class="filter-drawer"
          >
            <a-row class="mb-0 pb-0 mt-0 pt-0">
              <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :lg="{ span: 5 }" class="p-1">
                <a-form-model-item ref="filter-sport" label="Sport" prop="sport">
                  <a-select v-model="this.sport" @change="handleSportChange" size="large" style="width: 90%" :defaultValue="1">
                    <a-select-option :key="0" :value="undefined">Все</a-select-option>
                    <a-select-option v-for="(sport, index) in this.sports" :key="index" :value="sport">
                      {{ sport }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :lg="{ span: 5 }" class="p-1">
                <a-form-model-item ref="filter-champ" label="Champ" prop="champ">
                  <a-select v-model="this.champ" @change="handleChampChange" size="large" style="width: 90%">
                    <a-select-option :key="0" :value="undefined">Все</a-select-option>
                    <a-select-option v-for="(champ, index) in this.champs" :key="index" :value="champ">
                      {{ champ }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-drawer>
          <a-card>
            <a-table
              :columns="columns"
              :data-source="top_matches"
              :rowKey="(record, index) => index"
              class="analytics"
            >
            <span slot="time" slot-scope="time">
              <div>{{ $moment(time).format('DD.MM.YY')}}</div>
              <div :style="{color: '#595959'}">{{ $moment(time).format('HH:mm')}}</div>
            </span>
              <template slot="operation" slot-scope="text, record">
                <div class="row-operations d-flex justify-content-end">
                  <span>
                    <a-button @click="addToTop(record)">Add to top</a-button>
                  </span>
                </div>
              </template>

            </a-table>
          </a-card>
        </div>
      </a-col>
    </a-row>

    <a-modal
      v-model="matchModal"
      :footer="null"
      :title="'Add match to top'"
      class="match-modal"
      width="480px"
      :destroyOnClose="true"
    >
      <match-modal
        @closeModalFunction="matchModal = false"
        :record="record"
        :sections="top_sections"
        ref="matchModal"
      />
    </a-modal>
  </div>
</template>

<script>
// import dayjs from 'dayjs'
import apiClient from '@/services/axios'
import matchModal from '@/views/apps/analytics/modals/matchModal'

const columns = [
  {
    title: 'Date',
    dataIndex: 'time',
    key: 'time',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: 'Match',
    dataIndex: 'match_name',
    key: 'match_name',
    customRender: (text, record, index) => (record.match_name + '\n' + record?.champ_name),
  },
  // {
  //   title: 'Champ ID',
  //   dataIndex: 'champ_id',
  //   key: 'champ_id',
  //   align: 'left',
  // },
  // {
  //   title: 'Sport ID',
  //   dataIndex: 'sport_id',
  //   key: 'sport_id',
  //   align: 'right',
  // },
  {
    title: 'Sport',
    dataIndex: 'sport_name',
    key: 'sport_name',
  },
  {
    title: 'Match ID',
    dataIndex: 'match_id',
    key: 'match_id',
    align: 'right',
  },
  {
    title: 'Point',
    dataIndex: 'weight',
    key: 'weight',
    align: 'center',
  },
  {
    title: 'Actions',
    dataIndex: 'operation',
    key: 'operation',
    align: 'right',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  name: 'index',
  components: {
    matchModal,
  },
  data() {
    return {
      columns,
      champ: undefined,
      sport: undefined,
      champs: [],
      sports: [],
      top_matches: [],
      top_sections: [],
      matchModal: false,
      filtersDrawer: false,
      record: undefined,
    }
  },
  created() {
    this.getSports()
    this.getChamps()
    this.getTopMatches()
    this.getTopSections()
  },
  methods: {
    getSports() {
      const url = '/admin/analytics/top/sports'
      apiClient.get(url).then((response) => {
        this.sports = response.data
      }).catch(error => { console.log(error) })
    },
    getChamps() {
      const url = `/admin/analytics/top/champs?sport_name=${this.sport ?? ''}`
      apiClient.get(url).then((response) => {
        this.champs = response.data
      }).catch(error => { console.log(error) })
    },
    getTopMatches() {
      const url = `/admin/analytics/top/matches?sport_name=${this.sport ?? ''}&champ_name=${this.champ ?? ''}`
      apiClient.get(url).then((response) => {
        this.top_matches = response.data.data
        console.log(this.updates)
      }).catch(error => { console.log(error) })
    },
    handleSportChange(value) {
      this.sport = value
      this.getChamps()
      this.getTopMatches()
    },
    handleChampChange(value) {
      this.champ = value
      this.getTopMatches()
    },
    getTopSections() {
      const url = '/admin/top'
      apiClient.get(url).then((response) => {
        this.top_sections = response.data.data.filter(section => section.type.name === 'matches')[0]
      }).catch(error => { console.log(error) })
    },
    addToTop(record) {
      this.record = record
      this.matchModal = true
    },
    showFilters() {
      this.filtersDrawer = true
    },
    hideFilters() {
      this.filtersDrawer = false
    },
  },
}
</script>

<style scoped>
  .analytics /deep/ td{
    white-space: pre-line !important;
  }
</style>

<style>
.filter-drawer .ant-drawer-content-wrapper,
.filter-drawer .ant-drawer-content,
.filter-drawer .ant-drawer-wrapper-body {
  overflow: initial;
}
</style>
